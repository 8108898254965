import { Menu, Text, Burger } from '@mantine/core';
import { IconLogout, IconSettings } from '@tabler/icons';
import { useDisclosure } from '@mantine/hooks';
import { resetUserSession } from './AuthComponents/auth';

function BurgerMenu({ setIsAuthenticated }: any) {
  const [opened, { toggle }] = useDisclosure(false);
  const label = opened ? 'Close navigation' : 'Open navigation';
  return (
    <Menu onClose={toggle} shadow="md" width={200}>
      <Menu.Target>
        <Burger opened={opened} onClick={toggle} aria-label={label} />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>AnalyseDocs.com</Menu.Label>
        {/* <Menu.Item
          onClick={() => {
            window.location.href = '/account';
          }}
          icon={<IconSettings size={14} />}>
          Account
        </Menu.Item> */}
        {/* <Menu.Item icon={<IconMessageCircle size={14} />}>Messages</Menu.Item>
        <Menu.Item icon={<IconPhoto size={14} />}>Gallery</Menu.Item>
        <Menu.Item
          icon={<IconSearch size={14} />}
          rightSection={
            <Text size="xs" color="dimmed">
              ⌘K
            </Text>
          }>
          Search
        </Menu.Item> */}

        <Menu.Divider />

        {/* <Menu.Label></Menu.Label> */}
        {/* <Menu.Item icon={<IconArrowsLeftRight size={14} />}>Transfer my data</Menu.Item> */}
        <Menu.Item
          onClick={() => {
            resetUserSession();
            setIsAuthenticated(false);
          }}
          icon={<IconLogout size={14} />}>
          Log Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default BurgerMenu;
