import { useState } from 'react';

import { Radio, Group, Text, Badge, Button, Stack } from '@mantine/core';
import DataRow from '../DataRow/DataRow';
import Break from '../Break';
import PaymentIntent from './PaymentIntent';
import { IconMessageCircle, IconSearch } from '@tabler/icons';

function ChooseCredits() {
  const [total, setTotal] = useState('0.99');
  const [isCheckout, setIsCheckout] = useState(false);

  const handleTotal = (value: string) => {
    // Convert to cents
    setTotal(value);
  };

  if (isCheckout) {
    return <PaymentIntent total={total} />;
  }

  return (
    <>
      <Radio.Group
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          padding: '10px'
        }}
        defaultValue="0.99"
        onChange={(value) => handleTotal(value)}
        name="Select credits"
        label={<Text>Choose number of credits</Text>}
        description={
          <>
            <Break />
            <Group>
              <Text>
                <IconMessageCircle size={12} /> = Chat Credits
              </Text>
              <Text>
                <IconSearch size={12} /> = Search Credits
              </Text>
            </Group>
            <Break isLine />
          </>
        }>
        <Group position="apart" spacing="sm">
          <Group>
            <Radio value={'0.99'} />
            <Text size="sm" weight={400}>
              05 <IconMessageCircle size={12} /> + 10 <IconSearch size={12} /> =
            </Text>
          </Group>

          <Group>
            <Text weight={400} size="sm">
              {'$0.99'}
            </Text>
            <Badge color="green">Save 0% </Badge>
          </Group>
        </Group>
        <Group position="apart" spacing="sm">
          <div>
            <Group>
              <Radio value={'1.79'} />
              <Text size="sm" weight={400}>
                10 <IconMessageCircle size={12} /> + 20 <IconSearch size={12} /> =
              </Text>
            </Group>
          </div>

          <div>
            <Group>
              <Text weight={400} size="sm">
                {'$1.79'}
              </Text>
              <Badge color="green">Save 10% </Badge>
            </Group>
          </div>
        </Group>
        <Group position="apart" spacing="sm">
          <div>
            <Group>
              <Radio value={'2.99'} />
              <Text size="sm" weight={400}>
                20 <IconMessageCircle size={12} /> + 40 <IconSearch size={12} /> =
              </Text>
            </Group>
          </div>

          <div>
            <Group>
              <Text weight={400} size="sm">
                {'$2.99'}
              </Text>
              <Badge color="green">Save 20% </Badge>
            </Group>
          </div>
        </Group>
        <Group position="apart" spacing="sm">
          <div>
            <Group>
              <Radio value={'4.99'} />
              <Text size="sm" weight={400}>
                50 <IconMessageCircle size={12} /> + 100 <IconSearch size={12} /> =
              </Text>
            </Group>
          </div>

          <div>
            <Group>
              <Text weight={400} size="sm">
                {'$4.99'}
              </Text>
              <Badge color="green">Save 50% </Badge>
            </Group>
          </div>
        </Group>
      </Radio.Group>
      <Break isLine />
      <DataRow label="Total" data={'$ ' + total} />
      <Break isLine />
      <Group position="right">
        <Button fullWidth variant="gradient" onClick={() => setIsCheckout(true)}>
          Checkout
        </Button>
      </Group>
    </>
  );
}

export default ChooseCredits;
