import { useState } from 'react';
import { Modal, Button, Group, Title } from '@mantine/core';
import ChooseCredits from './ChooseCredits';

function PaymentModal() {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Title order={6}>Get Credits</Title>}>
        <ChooseCredits />
      </Modal>

      <Group position="center">
        <Button variant="gradient" compact onClick={() => setOpened(true)}>
          Get Credits
        </Button>
      </Group>
    </>
  );
}

export default PaymentModal;
