import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import PaymentSuccess from './components/PaymentSuccessPage';
import NotFoundPage from './components/NotFoundPage';
import LandingPage from './components/LandingPage';
import MainContentCombined from './components/MainContentCombined';
import mockdata from './components/Articles/articleData';
import ArticleTemplate from './components/Articles/ArticleTemplate';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // React StrictMode renders twice in development mode:
  // <StrictMode>
  // Iterate over mockdata and render each article using the ArticleTemplate component:

  <BrowserRouter>
    <Routes>
      {mockdata.map((article) => (
        <Route
          key={article.title}
          path={article.path}
          element={
            <App>
              <ArticleTemplate article={article} />
            </App>
          }
        />
      ))}
      <Route
        path="/app"
        element={
          <App>
            <MainContentCombined />
          </App>
        }
      />
      <Route
        path="payment-success"
        element={
          <App>
            <PaymentSuccess />
          </App>
        }
      />
      <Route
        path="/"
        element={
          <App>
            <LandingPage />
          </App>
        }
      />
      <Route
        path="*"
        element={
          <App>
            <NotFoundPage />
          </App>
        }
      />
    </Routes>
  </BrowserRouter>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
//
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
