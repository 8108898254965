import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  Group,
  Stack,
  List,
  Card,
  SimpleGrid
} from '@mantine/core';
import { Dots } from './Dots';
import exampleVideo from './Example.mp4';
import Break from './Break';
import { useViewportSize, useMediaQuery } from '@mantine/hooks';
import { IconMessage, IconRobot, IconSearch } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: 120,
    paddingBottom: 80,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 80,
      paddingBottom: 60
    }
  },

  inner: {
    position: 'relative',
    zIndex: 1
  },

  dots: {
    position: 'absolute',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],

    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },

  dotsLeft: {
    left: 0,
    top: 0
  },

  title: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
      fontSize: theme.fontSizes.md
    }
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column'
    }
  },

  control: {},
  root: {
    paddingTop: 10,
    paddingBottom: 10
  }
}));

function LandingPage() {
  const { classes } = useStyles();
  const { height, width } = useViewportSize();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const dots = [];
  for (let i = 75; i < 3000; i += 250) {
    dots.push(
      <Dots className={classes.dots} style={{ left: 0, top: i }} />,
      <Dots className={classes.dots} style={{ right: 0, top: i }} />
    );
  }

  return (
    <Container className={classes.root}>
      {!isMobile && dots}

      <Stack>
        <div>
          <Title className={classes.title}>AnalyseDocs.com</Title>
          <Text style={{ textAlign: 'center' }} color="dimmed" size="sm">
            Query docs on the fly, with AI.
          </Text>
          <Break height={1} />

          <Stack>
            <Card
              style={{
                width: '85%',
                alignSelf: 'center',
                borderRadius: 10,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
              }}>
              <Group position="left">
                <IconRobot size={40} />
                <Text color="dimmed" size="md">
                  AI-powered platform.
                </Text>{' '}
              </Group>
            </Card>
            <Card
              style={{
                width: '85%',
                alignSelf: 'center',
                borderRadius: 10,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
              }}>
              <Group position="left">
                <IconSearch size={40} />
                <Text color="dimmed" size="md">
                  Query docs and go directly to the source.
                </Text>{' '}
              </Group>
            </Card>
            <Card
              style={{
                width: '85%',
                alignSelf: 'center',
                borderRadius: 10,
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'
              }}>
              <Group position="left">
                <IconMessage size={40} />
                <Text color="dimmed" size="md">
                  Chat with your documents.
                </Text>
              </Group>
            </Card>
          </Stack>

          <Group position="center">
            <Button
              component="a"
              href="/app"
              variant="gradient"
              size="md"
              mt="xl"
              className={classes.control}>
              Enter App
            </Button>
          </Group>
          <Break />
        </div>

        <video width="100%" height="100%" controls>
          <source src={exampleVideo} type="video/mp4" />
        </video>
      </Stack>
      <Break isLine />
    </Container>
  );
}

export default LandingPage;
