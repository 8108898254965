import { ActionIcon } from '@mantine/core';
import { useMantineTheme } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons';

function ColorSchemeToggle({ toggleTheme }: any) {
  const theme = useMantineTheme();
  return (
    <ActionIcon
      onClick={() => toggleTheme()}
      size="md"
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.blue[6]
      })}>
      {theme.colorScheme === 'dark' ? (
        <IconSun size={20} stroke={1.5} />
      ) : (
        <IconMoonStars size={20} stroke={1.5} />
      )}
    </ActionIcon>
  );
}

export default ColorSchemeToggle;
