import ColorSchemeToggle from './components/ColorSchemeToggle/ColorSchemeToggle';
import { useState } from 'react';
import { themeDark, themeLight } from './themes';
import logo from './favicon32.png';
import {
  AppShell,
  Footer,
  Group,
  LoadingOverlay,
  MantineProvider,
  Header,
  Text,
  Anchor,
  Stack,
  Title
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import LoginModal from './components/AuthComponents/LoginModal';
import RegisterModal from './components/AuthComponents/RegisterModal';
import instance from './axiosApi';
import { useEffect, useRef } from 'react';
import { getUser, getToken, setUser, resetUserSession } from './components/AuthComponents/auth';
import PaymentModal from './components/PaymentComponents/PaymentModal';
import { IconMessageCircle, IconSearch } from '@tabler/icons';
import FaqSimple from './components/FAQs/FAQs';
import ArticlesCardsGrid from './components/Articles/ArticleGrid';
import BurgerMenu from './components/BurgerMenu';

function App(props: { children: React.ReactNode }) {
  const [theme, setTheme] = useState(themeDark);
  const toggleTheme = (value: any) => {
    const nextColorScheme = value || (theme === themeDark ? themeLight : themeDark);
    setTheme(nextColorScheme);
  };
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const faqRef = useRef(null) as any;
  const articlesRef = useRef(null) as any;
  const scrollToFaqRef = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const scrollToArticlesRef = () => {
    if (articlesRef.current) {
      articlesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Verify if user is logged in and has a valid token:
  // If yes, set isAuthenticated to true and set user
  // If no, set isAuthenticated to false and reset userSession
  const user = getUser();
  const token = getToken();

  useEffect(() => {
    if (!token) {
      setIsAuthenticating(false);
      return;
    }
    if (!user) {
      setIsAuthenticating(false);
      return;
    }

    const verifyToken = async () => {
      try {
        const response = await instance.post('/api/auth/verify', { token, user });
        setIsAuthenticating(false);
        setIsAuthenticated(true);
        setUser(response.data);
      } catch (error) {
        resetUserSession();
        setIsAuthenticating(false);
        setIsAuthenticated(false);
      }
    };
    verifyToken();
  }, [token, user]);

  if (isAuthenticating && token) {
    return (
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <AppShell
          footer={
            <Footer height={25} p="md">
              {' '}
            </Footer>
          }>
          <>
            <LoadingOverlay visible={isAuthenticating} />
          </>
        </AppShell>
      </MantineProvider>
    );
  }

  if (isAuthenticated) {
    return (
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <AppShell
          footer={
            <Footer height={40}>
              <Group p="sm" position="center">
                <Text size={14}>
                  <Anchor href="https://www.emcshared.com">Contact</Anchor>
                  {' | '}
                  <Anchor onClick={scrollToArticlesRef}>Articles</Anchor>
                  {' | '}
                  <Anchor onClick={scrollToFaqRef}>FAQ</Anchor>
                </Text>
              </Group>
            </Footer>
          }
          header={
            <Header height={60} p="xs">
              <Group position="apart">
                {/* When the logo is clicked refresh the page: */}
                <div
                  onClick={() => {
                    window.location.href = '/';
                  }}>
                  <Group position="center" style={{ gap: '5px' }}>
                    <img
                      style={{
                        width: '30px',
                        height: '30px',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        borderRadius: '50%',
                        cursor: 'pointer'
                      }}
                      src={logo}
                      alt="Logo"
                    />
                    {isMobile ? null : (
                      <>
                        <Title order={5}>AnalyseDocs.com</Title>
                        <Text size="xs" weight={500}>
                          {' | '}
                          Query docs on the fly, with AI.
                        </Text>
                      </>
                    )}
                  </Group>
                </div>

                <div>
                  {isMobile ? (
                    <Group position="center" style={{ gap: '5px' }}>
                      <Stack style={{ gap: '3px' }}>
                        <Group style={{ gap: '3px' }}>
                          <IconMessageCircle size={15} />
                          <Text size="xs" weight={500}>
                            {user.credits}
                          </Text>
                        </Group>
                        <Group style={{ gap: '3px' }}>
                          <IconSearch size={15} />
                          <Text size="xs" weight={500}>
                            {user.searchCredits}
                          </Text>
                        </Group>
                      </Stack>
                      <PaymentModal />
                      <BurgerMenu setIsAuthenticated={setIsAuthenticated} />
                      <ColorSchemeToggle toggleTheme={toggleTheme} />
                    </Group>
                  ) : (
                    <Group position="right">
                      <IconMessageCircle size={20} />
                      <Text size="xs" weight={500}>
                        {user.credits}
                      </Text>
                      <IconSearch size={20} />
                      <Text size="xs" weight={500}>
                        {user.searchCredits}
                      </Text>

                      <PaymentModal />
                      <BurgerMenu setIsAuthenticated={setIsAuthenticated} />
                      <ColorSchemeToggle toggleTheme={toggleTheme} />
                    </Group>
                  )}
                </div>
              </Group>
            </Header>
          }>
          <>
            {props.children}
            <div ref={faqRef}>
              <FaqSimple />
            </div>
            <div ref={articlesRef}>
              <ArticlesCardsGrid />
            </div>
          </>
        </AppShell>
      </MantineProvider>
    );
  } else {
    return (
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <AppShell
          footer={
            <Footer height={40}>
              <Group p="sm" position="center">
                <Text size={14}>
                  <Anchor href="https://www.emcshared.com">Contact</Anchor>
                  {' | '}
                  <Anchor onClick={scrollToArticlesRef}>Articles</Anchor>
                  {' | '}
                  <Anchor onClick={scrollToFaqRef}>FAQ</Anchor>
                </Text>
              </Group>
            </Footer>
          }
          header={
            <Header height={60} p="xs">
              <Group position="apart">
                <div
                  onClick={() => {
                    window.location.href = '/';
                  }}>
                  <Group position="center" style={{ gap: '5px' }}>
                    <img
                      style={{
                        width: '30px',
                        height: '30px',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        borderRadius: '50%',
                        cursor: 'pointer'
                      }}
                      src={logo}
                      alt="Logo"
                    />
                    {isMobile ? null : (
                      <>
                        <Title order={5}>AnalyseDocs.com</Title>
                        <Text size="xs" weight={500}>
                          {' | '}
                          Query docs on the fly, with AI.
                        </Text>
                      </>
                    )}
                  </Group>
                </div>

                <div>
                  <Group position="right">
                    <LoginModal setIsAuthenticated={setIsAuthenticated} />
                    <RegisterModal />
                    <ColorSchemeToggle toggleTheme={toggleTheme} />
                  </Group>
                </div>
              </Group>
            </Header>
          }>
          <>
            {props.children}
            <div ref={faqRef}>
              <FaqSimple />
            </div>
            <div ref={articlesRef}>
              <ArticlesCardsGrid />
            </div>
          </>
        </AppShell>
      </MantineProvider>
    );
  }
}

// <Anchor href="https://med.etoro.com/B19862_A94452_TClick_SnewCampaign.aspx">
//                   Sign Up
//                 </Anchor>
// <Anchor href="https://med.etoro.com/B19862_A94452_TClick_SnewCampaign.aspx">
//                   Sign Up
//                 </Anchor>

export default App;
