import { Grid, Tabs } from '@mantine/core';
import { useState } from 'react';
import { FileWithPath } from '@mantine/dropzone';
import PdfPreviews from './PdfPreviews/PdfPreviews';
import { useMediaQuery } from '@mantine/hooks';
import { IconFile, IconMessageCircle } from '@tabler/icons';
import Combined from './ChatBoxes/Combined';

interface Prompts {
  prompt: string;
  type: string;
}

// interface ResponseChat {
//   text: string;
//   id: string;
// }

interface ResponsesSearch {
  text: string;
  similarity: number;
  id: string;
  pageNumber: number;
  start: number;
  end: number;
}

function MainContentCombined() {
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const [documentText, setDocumentText] = useState('');
  const [isLargeDoc, setIsLargeDoc] = useState(false);
  const [isTooLarge, setIsTooLarge] = useState(false);
  const [chatCredits, setChatCredits] = useState(0);
  const [searchCredits, setSearchCredits] = useState(0);
  const [textLength, setTextLength] = useState(0);
  const [sentenceCount, setSentenceCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState<string | null>('Chat');
  const [searchResults, setSearchResults] = useState<any>([
    {
      text: '',
      start: 0,
      end: 0
    }
  ]);
  const isMobile = useMediaQuery('(max-width: 600px)');
  if (isMobile) {
    if (wordCount === 0) {
      return (
        <>
          <Combined
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            documentText={documentText}
            setDocumentText={setDocumentText}
            setIsLargeDoc={setIsLargeDoc}
            isLargeDoc={isLargeDoc}
            setIsTooLarge={setIsTooLarge}
            isTooLarge={isTooLarge}
            chatCredits={chatCredits}
            setChatCredits={setChatCredits}
            searchCredits={searchCredits}
            setSearchCredits={setSearchCredits}
            textLength={textLength}
            setTextLength={setTextLength}
            sentenceCount={sentenceCount}
            setSentenceCount={setSentenceCount}
            wordCount={wordCount}
            setWordCount={setWordCount}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            searchText={searchText}
            setSearchText={setSearchText}
            setActiveTab={setActiveTab}
          />
        </>
      );
    } else {
      return (
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List grow>
            <Tabs.Tab icon={<IconMessageCircle size={14} />} value="Chat" />
            <Tabs.Tab icon={<IconFile size={14} />} value="Preview" />
          </Tabs.List>
          <Tabs.Panel value="Chat">
            <Combined
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              documentText={documentText}
              setDocumentText={setDocumentText}
              setIsLargeDoc={setIsLargeDoc}
              isLargeDoc={isLargeDoc}
              setIsTooLarge={setIsTooLarge}
              isTooLarge={isTooLarge}
              chatCredits={chatCredits}
              setChatCredits={setChatCredits}
              searchCredits={searchCredits}
              setSearchCredits={setSearchCredits}
              textLength={textLength}
              setTextLength={setTextLength}
              sentenceCount={sentenceCount}
              setSentenceCount={setSentenceCount}
              wordCount={wordCount}
              setWordCount={setWordCount}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              searchText={searchText}
              setSearchText={setSearchText}
              setActiveTab={setActiveTab}
            />
          </Tabs.Panel>
          <Tabs.Panel value="Preview">
            <PdfPreviews
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              documentText={documentText}
              setDocumentText={setDocumentText}
              isLargeDoc={isLargeDoc}
              setIsLargeDoc={setIsLargeDoc}
              isTooLarge={isTooLarge}
              setIsTooLarge={setIsTooLarge}
              chatCredits={chatCredits}
              setChatCredits={setChatCredits}
              searchCredits={searchCredits}
              setSearchCredits={setSearchCredits}
              textLength={textLength}
              setTextLength={setTextLength}
              sentenceCount={sentenceCount}
              setSentenceCount={setSentenceCount}
              wordCount={wordCount}
              setWordCount={setWordCount}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Tabs.Panel>
        </Tabs>
      );
    }
  }
  return (
    <>
      <Grid>
        <Grid.Col lg={6} md={12} style={{ padding: '5px' }}>
          <Combined
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            documentText={documentText}
            setDocumentText={setDocumentText}
            setIsLargeDoc={setIsLargeDoc}
            isLargeDoc={isLargeDoc}
            setIsTooLarge={setIsTooLarge}
            isTooLarge={isTooLarge}
            chatCredits={chatCredits}
            setChatCredits={setChatCredits}
            searchCredits={searchCredits}
            setSearchCredits={setSearchCredits}
            textLength={textLength}
            setTextLength={setTextLength}
            sentenceCount={sentenceCount}
            setSentenceCount={setSentenceCount}
            wordCount={wordCount}
            setWordCount={setWordCount}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            searchText={searchText}
            setSearchText={setSearchText}
            setActiveTab={setActiveTab}
          />
        </Grid.Col>
        <Grid.Col lg={6} md={12} style={{ padding: '5px' }}>
          <PdfPreviews
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            documentText={documentText}
            setDocumentText={setDocumentText}
            isLargeDoc={isLargeDoc}
            setIsLargeDoc={setIsLargeDoc}
            isTooLarge={isTooLarge}
            setIsTooLarge={setIsTooLarge}
            chatCredits={chatCredits}
            setChatCredits={setChatCredits}
            searchCredits={searchCredits}
            setSearchCredits={setSearchCredits}
            textLength={textLength}
            setTextLength={setTextLength}
            sentenceCount={sentenceCount}
            setSentenceCount={setSentenceCount}
            wordCount={wordCount}
            setWordCount={setWordCount}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

export default MainContentCombined;
