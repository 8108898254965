import { useState } from 'react';
import { Modal, Button, Group, Title } from '@mantine/core';
import RegisterForm from './Register';

function RegisterModal() {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Title order={6}>Register</Title>}>
        <RegisterForm />
      </Modal>

      <Group position="center">
        <Button variant="gradient" compact onClick={() => setOpened(true)}>
          Register
        </Button>
      </Group>
    </>
  );
}

export default RegisterModal;
