import { Card, Text, List, AspectRatio, Image, createStyles, Container } from '@mantine/core';
import Break from '../Break';
import { Dots } from '../Dots';

interface Article {
  article: {
    image: string;
    date: string;
    title: string;
    Intro: string;
    Item1: string;
    Item2: string;
    Item3: string;
    Item4: string;
    Item5: string;
    Item6: string;
    Item7: string;
    Item8: string;
    Item9: string;
    Item10: string;
    Conclusion: string;
  };
}
const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600
  },

  titleMain: {
    marginBottom: theme.spacing.xs
  },

  text: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 200
    // lineHeight: 1.5
  },
  dots: {
    position: 'absolute',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],

    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  },

  dotsLeft: {
    left: 0,
    top: 0
  }
}));

function ArticleTemplate({ article }: Article) {
  const { classes } = useStyles();
  let margin = '0px';
  if (window.innerWidth > 768) {
    margin = '150px';
  }
  const dots = [];
  for (let i = 75; i < 3000; i += 250) {
    dots.push(
      <Dots className={classes.dots} style={{ left: 0, top: i }} />,
      <Dots className={classes.dots} style={{ right: 0, top: i }} />
    );
  }

  return (
    <>
      {dots}
      <Image
        style={{
          width: '200px',
          height: '200px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '20px'
        }}
        src={article.image}
        alt="Image for current article."
      />
      <Text
        style={{
          textAlign: 'center'
        }}
        color="dimmed"
        size="xs"
        transform="uppercase"
        weight={700}>
        {article.date}
      </Text>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: '20px'
        }}
        className={classes.title}>
        {article.title}
      </Text>
      <Container size="sm">
        <Text color="dimmed">{article.Intro}</Text>
        <Break height={2} />
        <List
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            fontWeight: 200
          }}
          type="ordered">
          <List.Item>
            <Text>{article.Item1}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item2}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item3}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item4}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item5}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item6}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item7}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item8}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item9}</Text>
          </List.Item>
          <Break />
          <List.Item>
            <Text>{article.Item10}</Text>
          </List.Item>
        </List>

        <Break height={2} />
        <Text color="dimmed">{article.Conclusion}</Text>
      </Container>
      <Break height={2} />
    </>
  );
}

export default ArticleTemplate;
