import { Container, Title, Accordion, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.md
  },

  title: {
    marginBottom: theme.spacing.sm
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.md,

    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  }
}));

const semanticSearch =
  'Semantic search is an advanced search technology that goes beyond traditional keyword search. It can interpret the meaning of words in a search query and understand the context of the query to provide more accurate and relevant search results. Unlike chat, search returns precise information that matches your search query.';
const slowQueries =
  'The initial query may take longer to process because it requires taking embeddings of your document. However, subsequent queries should be faster since the necessary information has already been indexed and processed. Therefore, you can expect faster results after the first query. We will continue to improve the speed and will offer a subscription option for those that need faster results.';
const docChat =
  'Doc Chat answers your queries like a chat bot. It uses contextual information from the document to provide relevant answers to your questions. Additionally, you can click on the sources to view where the chat bot has gathered its information from. This feature allows for a more interactive and informative search experience but may be prone to generating irrelevant or false information.';
const creditCardInfo =
  'We use Stripe to process credit card payments. Stripe is a PCI Level 1 Service Provider, which is the most stringent level of certification available in the payments industry.';
const docInfo = 'We do not store any information about your documents.';

function FaqSimple() {
  const { classes } = useStyles();
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title order={4} align="center" className={classes.title}>
        Frequently Asked Questions
      </Title>

      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="docChat">
          <Accordion.Control>What is Doc Chat?</Accordion.Control>
          <Accordion.Panel>{docChat}</Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item className={classes.item} value="search">
          <Accordion.Control>What is Search?</Accordion.Control>
          <Accordion.Panel>{semanticSearch}</Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item className={classes.item} value="searchSlow">
          <Accordion.Control>Why are my queries slow?</Accordion.Control>
          <Accordion.Panel>{slowQueries}</Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item className={classes.item} value="credit-card">
          <Accordion.Control>Do you store credit card information securely?</Accordion.Control>
          <Accordion.Panel>{creditCardInfo}</Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item className={classes.item} value="docInfo">
          <Accordion.Control>Do you store any information about my documents?</Accordion.Control>
          <Accordion.Panel>{docInfo}</Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

export default FaqSimple;
