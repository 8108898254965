import {
  createStyles,
  SimpleGrid,
  Card,
  Image,
  Text,
  Container,
  AspectRatio,
  Title
} from '@mantine/core';
import mockdata from './articleData';
import Break from '../Break';

const useStyles = createStyles((theme) => ({
  card: {
    transition: 'transform 150ms ease, box-shadow 150ms ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows.md
    }
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 600
  },

  titleMain: {
    marginBottom: theme.spacing.xs
  },

  text: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 200,
    lineHeight: 1.5
  }
}));

function ArticlesCardsGrid() {
  const { classes } = useStyles();
  const cards = mockdata.map((article) => (
    <Card
      key={article.title}
      p="xs"
      radius="md"
      component="a"
      onClick={
        // Onlciy open new tab if the article is not the current one
        () => {
          window.location.href = article.path;
        }
      }
      className={classes.card}>
      <AspectRatio ratio={1920 / 1080}>
        <Image src={article.image} alt="Image for current article." />
      </AspectRatio>
      <Text color="dimmed" size="xs" transform="uppercase" weight={700} mt="md">
        {article.date}
      </Text>
      <Text className={classes.title} mt={5}>
        {article.title}
      </Text>
    </Card>
  ));

  return (
    <Container py="xs">
      <Title order={4} align="center" className={classes.titleMain}>
        GPT Articles
      </Title>
      <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {cards}
      </SimpleGrid>
      <Break />
    </Container>
  );
}

export default ArticlesCardsGrid;
