import { TextInput, Text, Button, Group, LoadingOverlay, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import instance from '../../axiosApi';
import { setUserSession } from './auth';
import { useState } from 'react';

function LoginForm({ setIsAuthenticated }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    }
  });

  const handleLogin = () => {
    setIsLoading(true);
    instance
      .post('/api/auth/login', {
        email: form.values.email.toLowerCase(),
        password: form.values.password
      })
      .then((response) => {
        console.log(response);
        setUserSession(response.data.token, response.data.user);
        setIsLoading(false);
        setMessage('Login successful');
        setIsAuthenticated(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setMessage('Login failed. Please check your email and password');
      });
  };

  return (
    <form onSubmit={form.onSubmit(handleLogin)}>
      <div>
        <LoadingOverlay visible={isLoading} />
        <TextInput
          withAsterisk
          label="Email"
          placeholder="your@email.com"
          {...form.getInputProps('email')}
        />
        <PasswordInput
          withAsterisk
          label="Password"
          placeholder="Password"
          {...form.getInputProps('password')}
        />
        <Group position="right" mt="md">
          <Button variant="gradient" fullWidth compact type="submit">
            Login
          </Button>
        </Group>
        {/* Conditional formatting for whether the message is success or not: */}
        <Text size="sm" color={message.includes('success') ? 'green' : 'red'}>
          {message}
        </Text>
      </div>
    </form>
  );
}

export default LoginForm;
