import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
  Group
} from '@mantine/core';
// Import an image from the public folder:
import image from '../logo512.png';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32
    }
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%'
    }
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    }
  }
}));

function PaymentSuccess() {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
        <Image radius="lg" src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>Thank you for ordering credits</Title>
          <Text color="dimmed" size="lg">
            Head back to the home page to start querying your documents. The credits might take a
            few minutes to show up in your account.
          </Text>
          <Group position="center" style={{ gap: '5px' }}>
            <Button
              onClick={() => {
                window.location.href = '/app';
              }}
              variant="gradient"
              size="md"
              mt="xl"
              className={classes.control}>
              Enter App
            </Button>
          </Group>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  );
}

export default PaymentSuccess;
