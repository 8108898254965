import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import instance from '../../axiosApi';
import CheckoutForm from './CheckoutForm';
import { getToken, getUser } from '../AuthComponents/auth';
import { Text } from '@mantine/core';
import DataRow from '../DataRow/DataRow';
import Break from '../Break';
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// const STRIPE_PUBLISHABLE_TEST_KEY =
//   'pk_test_51IFQmEF4mWQ89nCWIeq5ah4hDEfCtv3eF2RPSnzF2HzATt6NltryMqsTXSnTXqd4D9q0aN3wE2kyfKVvQOidlPkA00a0sh1F9Y';
const STRIPE_PUBLISHABLE_PROD_KEY =
  'pk_live_51IFQmEF4mWQ89nCWzFrF6gCQDGBPyVeSNK0M00Dvo77XLPcvQg1tcV4TjVBjEOPG3esN6Zj2YLtYQxiOO2n6XGCC00fAHdfP7g';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_PROD_KEY);

function PaymentIntent({ total }: { total: string }) {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const user = getUser();
    const token = getToken();
    instance
      .post(
        'api/payments/create-payment-intent',
        {
          items: [{ id: total + ' ' + 'Credits' }],
          user: user
        },

        {
          headers: { 'Content-Type': 'application/json', Authorization: token }
        }
      )
      .then((res) => res.data)
      .then((data) => setClientSecret(data.clientSecret));
  }, [total]);

  let credits = 0;
  let searchCredits = 0;

  switch (total) {
    case '0.99':
      credits = 5;
      searchCredits = 10;
      break;
    case '1.79':
      credits = 10;
      searchCredits = 20;
      break;
    case '2.99':
      credits = 20;
      searchCredits = 40;
      break;
    case '4.99':
      credits = 50;
      searchCredits = 100;
      break;
    default:
      break;
  }

  const appearance = {
    theme: 'light'
  };
  const options = {
    clientSecret,
    appearance
  } as any;

  if (credits === 5 || credits === 10 || credits === 20 || credits === 30 || credits === 50) {
    return (
      <div>
        <Text
          style={{
            textAlign: 'center'
          }}>
          Review your order
        </Text>
        <Break height={1.5} />
        {credits > 0 ? (
          <DataRow label="Chat credits" data={credits} />
        ) : (
          <Text color="red">Invalid credit choice</Text>
        )}
        <Break />
        {searchCredits > 0 ? (
          <DataRow label="Search credits" data={searchCredits} />
        ) : (
          <Text color="red">Invalid search credit choice</Text>
        )}
        <Break />
        <DataRow label="Total" data={'$ ' + total} />
        <Break isLine />

        <Text
          style={{
            textAlign: 'center'
          }}>
          Add Payment Details
        </Text>
        <Break height={1.5} />

        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Text color="red">Invalid credit choice</Text>
        <Break />
        <DataRow label="Total" data={'$ ' + total} />
        <Break isLine />
        <Text color="red">Please try again</Text>
        <Break />
      </div>
    );
  }
}

export default PaymentIntent;
